<template>
    <div style="height: 100%;">
        <div class="container-comment">
            <div class="col l-12 m-12 c-12">
                <h4 class="comment-title"><md-icon>question_answer</md-icon> <span>Đóng góp ý kiến</span></h4>
            </div>
            <div class="col l-12 m-12 c-12">
                <md-content class="md-scrollbar">
                    <div v-for="item in comments" :key="'comment-' + item.id" class="comment-item">
                        <div class="comment-name">
                            {{ item.staff.fullName }}
                            <span v-if="item.dept !== null">- {{ item.dept.name }}</span>
                        </div>
                        <div class="comment-date">{{ item.createdTime }}</div>
                        <div class="comment-content">{{ item.comment }}</div>
                    </div>
                </md-content>
            </div>
        </div>
        <div class="comment-action">
            <div class="c-action">
                <div :class="{'md-invalid': submitted && comment === '' }"><input v-model="comment" type="text" placeholder="Nhập ý kiến..." class="comment-input"/></div>
                <div class="btn-c"><a @click="onComment()">Gửi</a></div>
            </div>
        </div>
    </div>
</template>


<script>
    import commentService from '../api/commentService';
    import messageBox from '../utils/messageBox';
    import { mapActions } from 'vuex';

    export default {
        props: {
            objectId: String,
            objectType: Number
        },

        data: () => ({
            submitted: false,
            comment: '',
            comments: [],
        }),

        created() {
            this.getComments();
        },

        methods: {
            ...mapActions('common', ['setLoading']),

            onComment() {
                this.submitted = true;
                if (this.comment === '') {
                    return;
                }
                this.setLoading(true);
                const data = { objectId: this.objectId, objectType: this.objectType, comment: this.comment }
                commentService.add(data).then((response) => {
                    if(response.statusCode == 200){
                        this.comment = '';
                        this.submitted = false;
                        messageBox.showMessage("Gửi ý kiến thành công");
                        this.getComments();
                    }
                    else{
                        messageBox.showWarning(response.data);
                    }
                }).finally(() => { this.setLoading(false); this.showCommentDialog = false });
            },

            getComments(){
                this.setLoading(true);
                commentService.getComments(this.objectId, this.objectType).then((response) => {
                    if(response.statusCode == 200){
                        this.comments = response.data;
                    }
                    else{
                        messageBox.showWarning(response.data);
                    }
                }).finally(() => { this.setLoading(false); });
            },
        },
    }
</script>